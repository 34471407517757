import server from 'api/server';
import axiosRetry from 'axios-retry';
import { hideLoading } from 'react-redux-loading-bar';
import { toast } from 'react-toastify';
import { getDocument, getFields } from 'redux/selectors';
import { shouldRedirectLogin } from 'utils/auth';
import * as types from './types';

// Editor Actions
export function setCurrentPage(currentPage) {
  return {
    type: types.SET_CURRENT_PAGE,
    payload: currentPage
  };
}

export function setNumPages(numPages) {
  return {
    type: types.SET_NUM_PAGES,
    payload: numPages
  };
}
export function storeRenderedPages(pages) {
  return {
    type: types.STORE_RENDERED_PAGES,
    payload: pages
  };
}

export function setAnnotation(annotation) {
  return {
    type: types.SET_ANNOTATION,
    payload: annotation
  };
}
export function addField(annotation) {
  return {
    type: types.ADD_FIELD,
    payload: annotation
  };
}

export function deleteField(annotation) {
  return {
    type: types.DELETE_FIELD,
    payload: annotation.data.id
  };
}

export function setActiveField(annotation) {
  if (annotation === null) {
    return {
      type: types.SET_ACTIVE_FIELD,
      payload: null
    };
  } else {
    return {
      type: types.SET_ACTIVE_FIELD,
      payload: annotation.data.id
    };
  }
}
export const updateFieldsToServer =
  (documentId) => async (dispatch, getState) => {
    const document = getDocument(getState(), documentId);
    const fields = getFields(getState());
    dispatch(saveDocumentToServer({ ...document, fields }, false));
    dispatch(setFields(fields));
  };

export const updateField =
  (annotation, documentId) => async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_FIELD,
      payload: annotation
    });
    dispatch(updateFieldsToServer(documentId));
  };

export function setLeftPanel(panel) {
  return {
    type: types.SET_LEFT_PANEL,
    payload: panel
  };
}

export const setFields = (fields) => async (dispatch) => {
  dispatch({
    type: types.SET_FIELDS,
    payload: fields
  });
};

export function setCurrentSelector(selector) {
  return {
    type: types.SET_CURRENT_SELECTOR,
    payload: selector
  };
}

export function setEditorMode(mode) {
  return {
    type: types.SET_EDITOR_MODE,
    payload: mode
  };
}

// Auth actions
export const login =
  (email, token, password = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.LOGIN_REQUEST });
      const url = password ? '/auth/login/vanilla' : '/auth/login/verify';
      const response = await server.post(url, {
        email,
        token
      });
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: {
          accessToken: response.data.accessToken,
          user: response.data.user,
          error: '',
          isLoggedIn: true
        }
      });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : 'Cant reach the server!';
      console.log(errorMessage);
      dispatch({
        type: types.LOGIN_FAILURE,
        payload: errorMessage
      });
    }
  };

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: types.LOGOUT_REQUEST });
    await server.post('/auth/logout');
    dispatch({
      type: types.LOGOUT_SUCCESS
    });
    window.location.href = `${process.env.REACT_APP_BASE_URL}/login`;
  } catch (error) {
    dispatch({ type: types.LOGIN_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const refreshToken = () => async (dispatch) => {
  try {
    dispatch({ type: types.REFRESH_TOKEN_REQUEST });
    const response = await server.post('/auth/refresh_token');
    if (response.status === 200 && response.data.accessToken) {
      dispatch({
        type: types.REFRESH_TOKEN_SUCCESS,
        payload: {
          accessToken: response.data.accessToken,
          user: response.data.user,
          isLoggedIn: true
        }
      });
    } else {
      throw Error(`Error in refreshing token ${response.data}`);
    }
  } catch (error) {
    shouldRedirectLogin(history);
  }
};

export function updateAccessToken(token) {
  return {
    type: types.UPDATE_ACCESS_TOKEN,
    payload: token
  };
}

export const fetchUser = () => async (dispatch) => {
  try {
    dispatch({ type: types.GET_USER_REQUEST });
    const response = await server.get('/auth');
    dispatch({ type: types.GET_USER_SUCCESS, payload: response.data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.GET_USER_FAILURE,
      payload: error.response && error.response.data
    });
  }
};

// Document actions

export const fetchDocuments = () => async (dispatch) => {
  try {
    axiosRetry(server, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      }
    });
    dispatch({ type: types.GET_DOCUMENTS_REQUEST });
    const response = await server.get(
      `/documents/?timestamp=${new Date().getTime()}`
    );
    dispatch({
      type: types.GET_DOCUMENTS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({ type: types.GET_DOCUMENTS_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const fetchDocument = (documentId) => async (dispatch) => {
  try {
    axiosRetry(server, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      }
    });
    dispatch({ type: types.GET_DOCUMENT_REQUEST });
    const response = await server.get(`/documents/get/${documentId}`);
    dispatch({
      type: types.GET_DOCUMENT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({ type: types.GET_DOCUMENT_FAILURE, payload: error.message });
    console.log(error);
  }
};
export const fetchDocumentSchemas = () => async (dispatch) => {
  try {
    axiosRetry(server, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      }
    });
    dispatch({ type: types.GET_DOCUMENT_SCHEMAS_REQUEST });
    const response = await server.get(`/documents/schemas/`);
    dispatch({
      type: types.GET_DOCUMENT_SCHEMAS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: types.GET_DOCUMENT_SCHEMAS_FAILURE,
      payload: error.message
    });
    console.log(error);
  }
};

export const createDocument = (formBody) => async (dispatch) => {
  try {
    axiosRetry(server, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      }
    });
    dispatch({ type: types.CREATE_DOCUMENT_REQUEST });
    await server.post('/documents/create', formBody);
    dispatch({ type: types.CREATE_DOCUMENT_SUCCESS });
    // Fetch documents again
    dispatch(fetchDocuments());
  } catch (error) {
    dispatch({ type: types.CREATE_DOCUMENT_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const deleteDocument = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_DOCUMENT_REQUEST });
    await server.post('/documents/delete', { id });
    dispatch({ type: types.DELETE_DOCUMENT_SUCCESS });
    // Fetch documents again
    dispatch(fetchDocuments());

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    }
  } catch (error) {
    dispatch({ type: types.DELETE_DOCUMENT_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const saveDocumentToServer =
  (document, sendToast = true) =>
  async (dispatch) => {
    try {
      axiosRetry(server, {
        retries: 3,
        retryDelay: (retryCount) => {
          return retryCount * 1000;
        }
      });

      dispatch({ type: types.UPDATE_DOCUMENT_REQUEST });
      await server.post('/documents/update', { ...document });

      dispatch({ type: types.UPDATE_DOCUMENT_SUCCESS });
      sendToast &&
        toast.info('Document saved successfully', {
          position: 'bottom-left',
          autoClose: 2000
        });
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_DOCUMENT_FAILURE, payload: error.message });
      console.log(error);
    }
  };

// PDF actions
export const fetchPdf = (id) => async (dispatch) => {
  try {
    axiosRetry(server, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      }
    });
    dispatch({ type: types.GET_PDF_REQUEST });
    const response = await server.post('/pdf/', { documentId: id });
    dispatch({
      type: types.GET_PDF_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({ type: types.GET_PDF_FAILURE, payload: error.message });
    console.log(error);
  }
};

export const setRenderedPdf =
  (documentId, fields, values) => async (dispatch) => {
    try {
      axiosRetry(server, {
        retries: 3,
        retryDelay: (retryCount) => {
          return retryCount * 1000;
        }
      });
      dispatch({ type: types.GET_RENDEREDPDF_REQUEST });
      const response = await server.post('/pdf/create', {
        documentId,
        fields,
        values
      });
      dispatch({
        type: types.GET_RENDEREDPDF_SUCCESS,
        payload: { renderedPDF: response, documentId, fields, values }
      });
    } catch (error) {
      dispatch({ type: types.GET_RENDEREDPDF_FAILURE, payload: error.message });
      dispatch(hideLoading('DocumentPreview'));
    }
  };
export const clearPdf = () => {
  return { type: types.CLEAR_PDF };
};

export const clearRenderedPdf = () => {
  return { type: types.CLEAR_RENDERED_PDF };
};

export const clearRenderedPdfData = () => {
  return { type: types.CLEAR_RENDERED_PDF_DATA };
};

export const updateFieldName = (nameMap) => {
  return { type: types.UPDATE_FIELD_NAME, payload: nameMap };
};
