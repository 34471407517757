import server from 'api/server';
import FileTree from 'components/_common/FileTree';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import './style.scss';

const FolderPickerModal = ({ open, onClose, onSelect }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetchingFolders, setFetchingFolders] = useState(true);
  const [tree, setTree] = useState({});
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    const getFolders = async () => {
      const { data } = await server.get(`/apps/smartvault/tree`);
      setTree(data);
      setFetchingFolders(false);
    };
    getFolders();
  }, []);
  useEffect(() => {
    if (selected) {
      setError('');
    }
  });

  const Loading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <div class="modal_top sv_folder_picker_modal">
        <h3 class="title is-h3">Manage Files</h3>
        <div class="field">
          <p>Select the destination folder in your SmartVault account.</p>
          <div class="control">
            {fetchingFolders ? (
              <Loading />
            ) : (
              <FileTree
                tree={tree}
                selected={selected}
                setSelected={setSelected}
                disabledFn={(node) =>
                  node.data.nodeType === 'ContainerNodeType'
                }
              />
            )}
          </div>
          {error && <p class="help is-danger">{error}</p>}
        </div>
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              onClick={async () => {
                if (selected) {
                  onSelect(selected);
                  setError('');
                  onClose();
                } else {
                  setError('Please select a destination folder');
                }
              }}
              class={`button is-info is-small ${loading ? 'is-loading' : ''}`}
            >
              Save Changes
            </button>
          </div>
          <div class="control">
            <button
              onClick={() => {
                setError('');
                onClose();
              }}
              class="button is-info is-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default FolderPickerModal;
