import { getIcon } from 'components/WorkflowEditor/steps/availableSteps';
import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  activateWorkflow,
  deactivateWorkflow
} from 'redux/actions/workflowActions';
import DeleteWorkflowModal from './DeleteWorkflowModal';
import DuplicateWorkflowModal from './DuplicateWorkflowModal';
import './WorkflowBox.scss';

const WorkflowBox = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();

  const [isActive, updateIsActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  useOnClickOutside(ref, () => {
    updateIsActive(false);
  });

  let valid;
  if (props.data.steps && props.data.steps.length >= 1) {
    valid = true;
    props.data.steps.map((s) => {
      valid = valid && s.data.valid;
    });
  } else {
    valid = false;
  }

  function toggleActivation(e) {
    e.preventDefault();
    if (!valid) return;
    if (props.data.active) {
      dispatch(
        deactivateWorkflow({ id: props.data.id, name: props.data.name })
      );
      toast.info('Workflow deactivated');
    } else {
      dispatch(activateWorkflow({ id: props.data.id, name: props.data.name }));
      toast.info('Workflow activated');
    }
  }

  return (
    <>
      <DeleteWorkflowModal
        id={props.data.id}
        open={modalOpen}
        onClose={(e) => setModalOpen(false)}
      />
      <DuplicateWorkflowModal
        id={props.data.id}
        name={props.data.name}
        open={duplicateModalOpen}
        onClose={(e) => setDuplicateModalOpen(false)}
      />
      <div class="box workflow_box">
        <nav class="level is-mobile">
          <div
            onClick={(e) => {
              history.push(`/workflows/editor/${props.data.id}`);
            }}
            className="level-left icons"
          >
            <div class="level-item">
              {props.data.steps &&
                props.data.steps
                  .sort((a, b) => a.order - b.order)
                  .map((s, i) => {
                    return i + 1 === props.data.steps.length ? (
                      getIcon(s.type)
                    ) : (
                      <span>
                        {getIcon(s.type)} <i class="fa fa-caret-right"></i>
                      </span>
                    );
                  })}
            </div>
          </div>
          <div
            onClick={(e) => {
              history.push(`/workflows/editor/${props.data.id}`);
            }}
            class="level-left workflow_name"
          >
            <div class="level-item">
              <span>{props.data.name ? props.data.name : 'Draft'}</span>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <input
                id="activateWorkflow"
                type="checkbox"
                name="activateWorkflow"
                class="switch is-rounded is-success"
                // onChange={toggleActivation}
                checked={props.data.active}
                disabled={!valid}
              />
              <label
                onClick={toggleActivation}
                className="switch_label"
                for="activateWorkflow"
              ></label>
            </div>
            <div class="level-item">
              <div class={`dropdown is-right ${isActive ? 'is-active' : ''}`}>
                <div class="dropdown-trigger">
                  <button
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    className="button is-small"
                    onClick={(e) => {
                      updateIsActive(!isActive);
                    }}
                  >
                    <i class="menu fas fa-ellipsis-h"></i>
                  </button>
                </div>

                <div
                  class="dropdown-menu"
                  style={{ width: `${props.width}px` }}
                  id="dropdown-menu"
                  role="menu"
                  ref={ref}
                >
                  <div class="dropdown-content">
                    <Link
                      to={`/workflows/editor/${props.data.id}`}
                      class="dropdown-item"
                    >
                      <i class="far fa-edit"></i> Edit
                    </Link>
                    <a
                      href="#"
                      onClick={(e) => {
                        setDuplicateModalOpen(true);
                        updateIsActive(false);
                      }}
                      class="dropdown-item"
                    >
                      <i class="far fa-copy"></i> Duplicate
                    </a>
                    <a
                      href="#"
                      onClick={(e) => {
                        setModalOpen(true);
                        updateIsActive(false);
                      }}
                      class="dropdown-item delete-item"
                    >
                      <i class="far fa-trash-alt"></i> Delete
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
export default WorkflowBox;
