import server from 'api/server';
import React, { useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchDocuments, refreshToken } from 'redux/actions';
import { getAccessToken } from 'redux/selectors';
import './Uploader.scss';

const Uploader = ({ onSuccess, currentFolder, fetchFiles }) => {
  //API: https://react-dropzone-uploader.js.org/docs/api
  useEffect(() => {
    dispatch(refreshToken());
  }, []);

  const dispatch = useDispatch();
  const accessToken = useSelector(getAccessToken);

  const handleChangeStatus = async ({ meta, file }, status) => {
    if (status === 'done') {
      onSuccess();
      dispatch(fetchDocuments());
      await fetchFiles();
      toast.info('Document uploaded successfully. Processing...');
    }
  };
  const uploadParams = (meta) => {
    const folderId = currentFolder?.id ? currentFolder.id : '';
    return {
      url: `${server.defaults.baseURL}/documents/create/${folderId}`,
      headers: {
        authorization: `bearer ${accessToken}`
      }
    };
  };
  const handleSubmit = (params) => {};
  return (
    <Dropzone
      getUploadParams={uploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      canCancel={false}
      onSubmit={handleSubmit}
      SubmitButtonComponent={null}
      inputContent="Drop or click to upload your document here"
      accept=".pdf,.doc,.docx,.ppt,.pptx"
    />
  );
};
export default Uploader;
