import server from 'api/server';
import { ForgotForm, ResetForm, VerifyCodeForm } from 'components/Auth/Reset';
import { useFormik } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fetchDocuments, login } from 'redux/actions';
import { fetchWorkflows } from 'redux/actions/workflowActions';
import { isActionPending, isLoggedIn } from 'redux/selectors';

import * as Yup from 'yup';
import '../Login/Login.scss';

const SVLogin = (props) => {
  const { email: emailQueryParam } = queryString.parse(props.location.search, {
    ignoreQueryPrefix: true
  });

  let history = useHistory();
  const dispatch = useDispatch();
  const [view, setView] = useState(props.view || 'login');
  const [reset, setReset] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authUri, setAuthUri] = useState(null);

  const [email, setEmail] = useState(emailQueryParam || '');
  const [code, setCode] = useState('');

  const pendingState = useSelector((state) => isActionPending(state, 'LOGIN'));
  const pendingDocuments = useSelector((state) =>
    isActionPending(state, 'GET_DOCUMENTS')
  );
  const pendingWorkflows = useSelector((state) =>
    isActionPending(state, 'GET_WORKFLOWS')
  );

  const loggedIn = useSelector(isLoggedIn);

  const onLoginSubmit = async () => {
    const { email, password } = formikLogin.values;
    try {
      setLoading(true);
      setEmail(email);
      const response = await server.post('/auth/login', { email, password });
      if (!response) throw Error(`No response from the server!`);
      setLoading(false);
      setError(null);
      setView('login-verify2FA');
    } catch (err) {
      if (
        typeof err.response.data !== 'string' &&
        'authorized' in err.response.data &&
        !err.response.data.authorized
      ) {
        setView('authorize');
        setAuthUri(err.response.data.authorizationUri);
      } else if (err.response && err.response.data) {
        setError(err.response.data);
      } else {
        setError(err.message);
      }
      setLoading(false);
    }
  };

  const onVerify2FASubmit = async () => {
    const { token } = formik2FA.values;
    const { email } = formikLogin.values;
    dispatch(login(email, token));
  };

  const onVerify = async () => {
    try {
      setError('');
      setLoading(true);
      const { data } = await server.post('/apps/smartvault', {
        email
      });
      setLoading(false);
      if (data.authorized && data.accountSelected) {
        await onLoginSubmit();
        setAuthUri(null);
      } else {
        setError(
          'Docdown is not authorized to access your SmartVault account yet!'
        );
      }
    } catch (err) {
      setLoading(false);

      if (err?.response?.data?.message && err?.response?.status === 400) {
        setError(err.response.data.message);
      }
    }
  };

  const formikLogin = useFormik({
    initialValues: {
      email: emailQueryParam || '',
      password: ''
    },
    onSubmit: onLoginSubmit,
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Required'),
      password: Yup.string().required('Password is required')
    })
  });

  const formik2FA = useFormik({
    initialValues: {
      token: ''
    },
    onSubmit: onVerify2FASubmit,
    validationSchema: Yup.object().shape({
      token: Yup.string().required('Required')
    })
  });

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchDocuments());
      dispatch(fetchWorkflows());
    }
  }, [loggedIn]);

  useEffect(() => {
    async function navigate() {
      function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
      if (loggedIn && pendingWorkflows && pendingDocuments) {
        if (pendingWorkflows.pending && pendingDocuments.pending) {
          await sleep(500);
          history.push(`/${props.location.search}`);
        }
      }
    }
    navigate();
  }, [loggedIn, pendingDocuments, pendingWorkflows]);

  const renderloader = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
        <span>Signing in...</span>
      </div>
    );
  };

  const renderLoginForm = () => {
    return (
      <form onSubmit={formikLogin.handleSubmit}>
        <h4 className="title is-5">Log in to your account</h4>
        {reset && (
          <article className="message is-success">
            <div className="message-body ">
              <span className="is-small">
                Your password has been successfully changed
              </span>
            </div>
          </article>
        )}
        <div className="control">
          <label htmlFor="email" className="label">Email</label>
          <input
            name="email"
            className="input"
            onChange={formikLogin.handleChange}
            value={formikLogin.values.email}
            disabled={emailQueryParam ? true : false}
          />
        </div>

        <div className="control">
          <label htmlFor="password" className="label">Password</label>
          <input
            name="password"
            className="input"
            type="password"
            onChange={formikLogin.handleChange}
            value={formikLogin.values.password}
          />
        </div>

        {error && (
          <p className="help is-danger">
            <span>{error}</span>
          </p>
        )}

        <div className={`field`}>
          <button
            type="submit"
            className={`button is-fullwidth is-info ${loading && 'is-loading'}`}
          >
            Continue
          </button>
        </div>
        <span className="login-links">
          <Link to={`/reset${props.location.search}`}>Forgot Password? </Link>
        </span>
      </form>
    );
  };

  const render2FAForm = () => {
    return (
      <>
        <form onSubmit={formik2FA.handleSubmit}>
          <h4 className="title is-5">Verify it's you</h4>
          <p className="help">
            We sent a verification code to your email. Enter the code from the
            email in the field below.
          </p>
          <div className="control">
            <input
              name="token"
              className="input"
              value={formik2FA.values.token}
              onChange={formik2FA.handleChange}
            />
          </div>
          <p className="help is-danger">
            {pendingState && pendingState.error && (
              <span>{pendingState.error}</span>
            )}
          </p>

          <div className="field">
            <button
              type="submit"
              className={`button is-fullwidth is-info ${pendingState && pendingState.pending && 'is-loading'
                }`}
            >
              Verify Code
            </button>
          </div>
          <span className="login-links">
            <a href={`/login${props.location.search}`}>Back to Login</a>
          </span>
        </form>
      </>
    );
  };

  const renderAuthorization = () => {
    return (
      <div className="form">
        <h4 className="title is-h4">Authenticate SmartVault Account</h4>
        <p>
          Please click on the following button to allow Docdown to access your
          SmartVault account.
        </p>
        <hr />
        <div className="field is-grouped">
          <div className="control">
            <button
              onClick={() => {
                window.open(authUri, '_blank').focus();
                setView('verify');
              }}
              className={`button is-info is-small ${loading ? 'is-loading' : ''}`}
              id="SVLogin-Button-Authenticate"
            >
              Authenticate Docdown
            </button>
          </div>
          <div className="control"></div>
        </div>
      </div>
    );
  };
  const renderVerify = () => {
    return (
      <div className="form">
        <h4 className="title is-h3">Verify SmartVault Account</h4>
        <p>
          After allowing Docdown to access your account, please click on the
          verify button below.
        </p>
        {error && <p className="help is-danger">{error}</p>}
        <hr />
        <div className="field is-grouped">
          <div className="control">
            <button
              onClick={onVerify}
              className={`button is-info is-small ${loading ? 'is-loading' : ''}`}
              id="SVLogin-Button-Verify"
            >
              Verify
            </button>
          </div>
          <div className="control"></div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    if (loggedIn) {
      return renderloader();
    } else {
      switch (view) {
        case 'login':
          return renderLoginForm();
        case 'login-verify2FA':
          return render2FAForm();
        case 'forgot':
          return (
            <ForgotForm
              onSuccess={(email) => {
                setEmail(email);
                setView('code');
              }}
              loginUrl={`/secure/login${props.location.search}`}
              onReturn={(e) => setView('login')}
            />
          );
        case 'code':
          return (
            <VerifyCodeForm
              email={email}
              onSuccess={(code) => {
                setCode(code);
                setView('reset');
              }}
            />
          );
        case 'reset':
          return (
            <ResetForm
              email={email}
              code={code}
              onSuccess={() => {
                setView('login');
                setReset(true);
              }}
            />
          );
        case 'authorize':
          return renderAuthorization();
        case 'verify':
          return renderVerify();
        default:
          return renderLoginForm();
      }
    }
  };

  const render = () => {
    return (
      <div className="login">
        <div className="columns is-mobile is-centered is-vcentered">
          <div className="column is-half is-desktop is-narrow">
            <div className="brand">
              <img src="/assets/docdown-logo.png" />
            </div>
            <section className="section">
              <div className="box">{renderForm()}</div>
            </section>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

export default SVLogin;
