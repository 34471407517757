import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import './FileExplorer.scss';
import CreateFolderModal from './components/CreateFolderModal';
import DropdownButton from './components/DropdownButton';
import { dropdownDirection } from './utils';

const FileExplorer = ({
  files,
  loading,
  history,
  setHistory,
  fetchFiles,
  folderTree,
  currentFolder,
  setcurrentFolder,
  handleDocumentSelected,
  selectedRows
}) => {
  const [currentFiles, setCurrentFiles] = useState([]);
  const [openNewFolderModal, setOpenNewFolderModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    let fileObjects = files
      .filter((f) => f.parentId === currentFolder.id)
      .map((f) => {
        if (!f['user.name']) return f;
        const owner =
          f['user.status'] === 'active'
            ? f['user.name']
            : `${f['user.name']} (inactive)`;
        return { ...f, owner };
      });
    setCurrentFiles(fileObjects);
  }, [currentFolder, files]);

  const columns = [
    {
      name: 'Name',
      sortable: true,
      sortFunction: (a, b) => a.name.localeCompare(b.name),
      selector: 'fileName',
      width: '50%',
      cell: (row) => (
        <div>
          {row.type === 'document' && renderDocument(row)}
          {row.type === 'folder' && renderFolder(row)}
        </div>
      )
    },
    {
      name: 'Owner',
      selector: 'owner', // Specify the selector to enable sorting.
      sortable: true,
      sortFunction: (a, b) => a.owner.localeCompare(b.owner),
      cell: (row) => <span>{row.owner}</span>
    },
    {
      name: 'Created',
      selector: 'updatedAt',
      sortable: true,
      cell: (row) => <Moment format="lll">{row.createdAt}</Moment>
    },
    {
      name: 'Modified',
      selector: 'updatedAt',
      sortable: true,
      cell: (row) => <Moment format="lll">{row.updatedAt}</Moment>
    },

    {
      cell: (row, i) => (
        <DropdownButton
          data={row}
          onAction={fetchFiles}
          folderTree={folderTree}
          direction={dropdownDirection(i, currentFiles.length, rowsPerPage)}
          siblingFolders={currentFiles
            .filter((f) => f.type === 'folder')
            .map((f) => f.name)}
        />
      )
    }
  ];

  const renderBreadcrumb = () => {
    return (
      <div class="folder-breadcrumb">
        <i class="fas fa-folder"></i>
        {history.map((h, i) => (
          <>
            <a
              onClick={() => {
                setcurrentFolder(h);
                const idx = history.findIndex((a) => a.id === h.id);
                setHistory(history.slice(0, idx + 1));
              }}
            >
              {i > 2 && i !== history.length - 1 ? '...' : h.name}
            </a>
            {i !== history.length - 1 && <i class="fas fa-angle-right"></i>}
          </>
        ))}
      </div>
    );
  };

  const renderFolder = (file) => {
    return (
      <div class="file-item">
        <a
          onClick={() => {
            const newfolder = { id: file.id, name: file.name };
            setcurrentFolder(newfolder);
            setHistory([...history, newfolder]);
          }}
          title={file.name}
        >
          <i class="fas fa-folder"></i>
          {file.name}
        </a>
      </div>
    );
  };
  const renderDocument = (file) => {
    return (
      <div class="file-item">
        {file?.processing ? (
          <Loader type="Oval" color="#ccc" height={25} width={25} />
        ) : (
          <Link to={`/documents/editor/${file.id}`} title={file.name}>
            <i className="fas fa-file-pdf"></i> {file.name}
          </Link>
        )}
      </div>
    );
  };

  const Loading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };
  const EmptyFolder = () => {
    return (
      <div className="empty">
        <div class="content">
          <p>
            <i className="fas fa-file"></i>
          </p>
          <p>You don't have any documents here.</p>
          <p></p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CreateFolderModal
        open={openNewFolderModal}
        onClose={async () => {
          setOpenNewFolderModal(false);
          await fetchFiles();
        }}
        currentFolderId={currentFolder.id}
        siblingFolders={currentFiles
          .filter((f) => f.type === 'folder')
          .map((f) => f.name)}
      />
      <div className="box file-explorer">
        <nav class="level">
          <div class="level-left">
            <div class="level-item"> {renderBreadcrumb()}</div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <button
                onClick={(e) => setOpenNewFolderModal(true)}
                className="button button-folder is-small"
              >
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span>New Folder</span>
              </button>
            </div>
          </div>
        </nav>
        <DataTable
          title=""
          noHeader={selectedRows && selectedRows.length > 0 ? false : true}
          columns={columns}
          data={currentFiles}
          progressPending={loading}
          progressComponent={<Loading />}
          selectableRows
          onSelectedRowsChange={handleDocumentSelected}
          selectableRowDisabled={(row) => row.type === 'folder'}
          pagination
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          paginationComponentOptions={{
            selectAllRowsItem: true,
            selectAllRowsItemText: 'All'
          }}
          onChangeRowsPerPage={(row) => setRowsPerPage(row)}
          noDataComponent={<EmptyFolder />}
        />
      </div>
    </div>
  );
};
export default FileExplorer;
