import server from 'api/server';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';

const DeleteDocumentModal = ({ open, onClose, file }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onDelete = async () => {
    try {
      setLoading(true);
      const {
        data: { safe, success }
      } = await server.delete(`/documents/${file.id}`);
      if (!safe) {
        setLoading(false);
        setDisabled(true);
        setError('This document is in use and cannot be deleted!');
      }
      if (success) {
        onClose();
        setError('');
        setDisabled(false);
        setLoading(false);
        toast.info('Document deleted successfully.');
      }
    } catch (error) {
      setLoading(false);
      toast.info('Something went wrong!');
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setDisabled(false);
        setError('');
      }}
      closeOnOverlayClick={false}
    >
      <div class="modal_top">
        <h3 class="title is-h3">Delete Document</h3>
        <div class="field">
          {!error && (
            <p>
              This will permanently remove the document. This action cannot be
              undone.
            </p>
          )}
          {error && <p class="help is-danger">{error}</p>}
        </div>
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              disabled={disabled}
              onClick={onDelete}
              class={`button is-info is-small is-danger ${
                loading ? 'is-loading' : ''
              }`}
            >
              Delete Document
            </button>
          </div>
          <div class="control">
            <button
              onClick={() => {
                onClose();
                setDisabled(false);
                setError('');
              }}
              class="button is-info is-small is-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDocumentModal;
